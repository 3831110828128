<template>
  <div class="school-data-fund">
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click.prevent="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <DataTablePagination
      v-if="!isLoadingTable"
      class="my-6"
      :page="page"
      :total-items="totalItems"
      :headers="headers"
      :items="funds"
      :search="search"
      title="Dana Bos"
      subtitle="Halaman untuk menambahkan Dana Bos"
      :is-loading="isLoadingData"
      :no-add-button="true"
      :total-pages.sync="totalPages"
      @change-page="paginationHandler"
      @live-search="searchHandler"
      @check-description="checkDescription"
    >
    </DataTablePagination>

    <v-skeleton-loader
      v-else
      class="mt-6"
      v-bind="attrs"
      type="table-thead,table-tbody,table-tfoot"
    >
    </v-skeleton-loader>
  </div>
</template>

<script>
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import { integerValidator, required } from '@core/utils/validation'
import { mdiArrowLeft, mdiClose } from '@mdi/js'
import DataTablePagination from '../../components/DataTablePagination.vue'

export default {
  name: 'SchoolFund',
  components: {
    DataTablePagination,
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiArrowLeft,
      },
      validation: {
        required,
        integerValidator,
      },
      detail: {},
      formValid: false,
      modalDescription: false,
      width: 600,
      school: [],
      school_uuid: null,
      page: 1,
      isLoadingTable: true,
      imageOverlay: false,
      isLoadingData: false,
      isLoadingButton: false,
      totalItems: 10,
      totalPages: 0,
      search: '',
      dialog: '',
      service: 'schoolfund',
      // funds: [],
      fund: {
        title: '',
        description: '',
        school_uuid: '',
        total_spent: '',
        total_budget: '',
        date: '',
      },
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Judul', value: 'title' },
        { text: 'Total Anggaran', value: 'total_budget' },
        { text: 'Total Pengeluaran', value: 'total_spent' },
        { text: 'Tanggal', value: 'date' },
        { text: 'Deskripsi', value: 'description_fund' },
      ],
      funds: [],
    }
  },
  computed: {
    gPrice: {
      get() {
        const formatedPrice = toCurrencyFormat(this.fund.total_budget)

        return formatedPrice === 'NaN' ? null : formatedPrice
      },
      set(newValue) {
        const formatedPrice = toCurrencyFormat(newValue)
        this.fund.total_budget = formatedPrice === 'NaN' ? null : formatedPrice

        return this.fund.total_budget
      },
    },
    fPrice: {
      get() {
        const formatedPrice = toCurrencyFormat(this.fund.total_spent)

        return formatedPrice === 'NaN' ? null : formatedPrice
      },
      set(newValue) {
        const formatedPrice = toCurrencyFormat(newValue)
        this.fund.total_spent = formatedPrice === 'NaN' ? null : formatedPrice

        return this.fund.total_spent
      },
    },
  },
  watch: {
    fund: {
      handler() {
        const valid = []
        const requiredField = ['title', 'total_budget', 'total_spent']
        Object.entries(this.fund).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    search: {
      handler() {
        this.getFund()
      },
    },
    page: {
      handler() {
        this.getFund()
      },
    },
  },
  async mounted() {
    await this.getSchoolUuid()
    this.getFund()
  },
  methods: {
    async checkDescription(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.detail = data.data
      })
      this.modalDescription = true
    },

    async getFund(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(({ data }) => {
        this.funds = data.data.map((fund, index) => ({
          ...fund,
          index: index + 1,
        }))
        this.isLoadingTable = false
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
    },

    getSchoolUuid() {
      this.school_uuid = this.$route.params.uuid
    },
    async searchHandler(search) {
      await this.getFund({ search, school_uuid: this.school_uuid })
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
